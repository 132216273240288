import styled from '@emotion/styled';
import { Link } from 'gatsby';

import CustomTheme from 'interfaces/CustomTheme';

import * as Shared from '../../style';

export const BlogIndex = styled.section`
  ${Shared.indexLayoutStyle}
`;

export const BlogLink = styled(Link)`
  ${Shared.indexLinkStyle}
`;

export const BlogPreview = styled.article`
  ${Shared.indexArticleStyle}
`;

export const Title = styled.h2`
  ${Shared.indexTitleStyle}
`;

export const Caption = styled.span<{ theme: CustomTheme }>`
  ${({ theme }) => Shared.indexCaptionStyle(theme)}
`;
