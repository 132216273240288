import React from 'react';

import Layout from 'components/layout';
import BlogIndex from 'components/blog/blogIndex';

const BlogIndexPage = ({ location }: { location: Location }) => (
  <Layout 
    seo={{
      title: 'Blog',
    }} 
    location={location}
  >
    <BlogIndex />
  </Layout>
);


export default BlogIndexPage;