import { graphql, useStaticQuery } from 'gatsby';

import BlogIndexQueryData from 'interfaces/BlogIndexQueryData';

const useBlogIndexQuery = () => {
  const { allMdx }: BlogIndexQueryData = useStaticQuery(graphql`
    query BLOG_INDEX_QUERY {
      allMdx(sort: { fields: [frontmatter___publishedAt], order: DESC }) {
        nodes {
          id
          frontmatter {
            entryNumber
            title
            publishedAt
          }
          timeToRead
          slug
        }
      }
    }
  `);

  return { allMdx };
};

export default useBlogIndexQuery;