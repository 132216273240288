import { css } from '@emotion/core';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';

export const indexLayoutStyle = css`
  max-width: 1280px;
  padding: 6rem ${SPACER.base} ${SPACER.x5large} ${SPACER.base};
  margin: 0 auto;
  border-left: 1px dashed;
  border-right: 1px dashed;
  min-height: calc(100vh - ${SPACER.x4large});

  display: flex; 
  flex-direction: column;
`;

export const indexLinkStyle = css`
  display: inline-flex; 
  flex-direction: column;
`;

export const indexArticleStyle = css`
  margin: ${SPACER.xlarge} 0;
`;

export const indexTitleStyle = css`
  font-size: 32px;
  display: inline;
`;

export const indexCaptionStyle = (theme: CustomTheme) => css`
  font-family: ${theme.fonts.monospace};
  font-weight: ${theme.fontWeights.bold};
  margin-top: ${SPACER.small};
  display: inline-block;
  font-size: 14px;
`;